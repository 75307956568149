.url-wrapper {
  word-break: break-all; /* This will break the text at any character to prevent overflow */
  overflow-wrap: break-word; /* This is for breaking at appropriate points, like spaces */
}

.highlight {
  animation: flash 0.5s ease-in-out;
}

@keyframes flash {
  0% { background-color: rgb(202, 204, 252); }
  100% { background-color: transparent; }
}